import { es6InDepthImage, es6Image, reactJSImage, vueImage,
  reactvrImage, reduxImage, testingImage, tddImage, fullstackImage, nextReactImage, nodepsqlImage,
  dockerImage, blockchainImage, blockchainFullstackImage, reactBootcampImage, reactHooksImage,
  jsBootcampImage, buildEthereumImage, pythonBlockchainImage
} from '../assets';

const COURSES = [
  {
    id: 0,
    title: 'Next.js and React 18 Bootcamp | Build a Production Site',
    highlighted: true,
    pic: nextReactImage,
    link: 'https://www.udemy.com/course/nextjs-and-react-bootcamp/?referralCode=686A52209BC6FC20C7B7',
    description: `Build a production Next.js and React 18 site, w/ server and client components, React 18 hooks and Next.js hooks, & more.`,
    includeInSlider: true
  },
  {
    id: 1,
    title: 'Python, JS, & React | Build a Blockchain & Cryptocurrency',
    pic: pythonBlockchainImage,
    link: 'https://www.udemy.com/course/python-js-react-blockchain/?referralCode=9051A01550E782315B77',
    highlighted: true,
    description: `Build a blockchain and cryptocurrency with Python, JavaScript, and React! Backend and frontend web development included!`,
    includeInSlider: true
  },
  {
    id: 2,
    title: 'Ethereum Tutorial: Ethereum & Smart Contracts from Scratch',
    pic: buildEthereumImage,
    link: 'https://www.udemy.com/course/build-ethereum-from-scratch/?referralCode=73C996A83A95034785FE',
    highlighted: true,
    description: `Build your own version of Ethereum! Create a project with smart contracts, a blockchain, cryptocurrency, and more!`,
    includeInSlider: true
  },
  {
    id: 3,
    title: 'Learn JavaScript, Get Hired | The Full Bootcamp',
    pic: jsBootcampImage,
    link: 'https://www.udemy.com/course/learn-javascript-get-hired-bootcamp/?referralCode=9A45F6FB7DF5DA859A03',
    description: `JavaScript is the most in-demand skill. Get an extra advantage with this course's special JS interview question videos.`,
    includeInSlider: true
  },
  {
    id: 4,
    title: 'React Hooks Tutorial - Master React Hooks Development',
    pic: reactHooksImage,
    link: 'https://www.udemy.com/course/react-hooks-tutorial/?referralCode=A962DAFE38CD9E72C030',
    description: `Master React Hooks the right way. Learn what they are, how they work under the hood, and why they're so revolutionary!`,
    includeInSlider: true
  },
  {
    id: 5,
    title: 'React JS and Redux Bootcamp - Master React Web Development',
    pic: reactBootcampImage,
    link: 'https://www.udemy.com/course/react-redux-bootcamp/?referralCode=AFCB98B9B0921870326F',
    highlighted: true,
    description: 'Learn React.js and Redux the right way. Dive into the React engine, create 5 apps, and become a React web developer.',
    includeInSlider: true
  },
  {
    id: 6,
    title: 'Build a Blockchain and a Cryptocurrency | Full-Stack Edition',
    pic: blockchainFullstackImage,
    link: 'https://www.udemy.com/course/build-blockchain-full-stack/?referralCode=6FD5FF7DB40E9E65C714',
    highlighted: true,
    description: 'Full-stack blockchain programming course! A backend + frontend with Node.js, Jest, Express, React, Heroku, & more!',
    includeInSlider: true
  },
  {
    id: 7,
    title: 'Master Full-Stack Development | Node, SQL, React, and More',
    pic: fullstackImage,
    link: 'https://www.udemy.com/course/full-stack/?referralCode=604C153528BCED72276E',
    description: 'Build a fullstack project with Node.js, PostgreSQL, SQL, React, Redux and more! Covers APIs, authentication, and beyond!',
    includeInSlider: true
  },
  {
    id: 8,
    title: 'Build a Blockchain and a Cryptocurrency from Scratch',
    pic: blockchainImage,
    link: 'https://www.udemy.com/course/build-blockchain/?referralCode=82B5E28DA8CEA7C3D654',
    description: 'Build a blockchain and cryptocurrency. Discover the engineering ideas behind technologies like Bitcoin and Ethereum!',
    includeInSlider: true
  },
  {
    id: 9,
    title: 'Docker - Introducing Docker Essentials, Containers, and more',
    pic: dockerImage,
    highlighted: true,
    link: 'https://www.udemy.com/course/docker-containers/?referralCode=804A4777EF0BD8453787',
    description: 'Create and run Docker containers, and build multi-container applications. Compose, Networks, Swarm, Cloud, & more!',
    includeInSlider: true
  },
  {
    id: 10,
    title: 'Node and PostgreSQL - Mastering Backend Web Development',
    pic: nodepsqlImage,
    link: 'https://www.udemy.com/course/node-postgresql/?referralCode=C40DA33FCDB1BACE0525',
    description: 'Boost your Node, Express, SQL, PostgreSQL and Backend Development Skills! NodeJS, Databases, Express, JS, ES6, + more!',
    includeInSlider: false
  },
  {
    id: 11,
    title: 'React - Mastering Test and Behavior Driven Development',
    pic: tddImage,
    link: 'https://www.udemy.com/course/react-tdd/?referralCode=F8A6A5877B0BD8636936',
    description: 'Upgrade your React skills with Test Driven Development! Jest, Enzyme, Redux, middleware, ES6, & more!',
    includeInSlider: false
  },
  {
    id: 12,
    title: 'React, Redux, & Enzyme - Mastering Apps & Tests',
    pic: testingImage,
    link: 'https://www.udemy.com/course/react-testing/?referralCode=8A5B9895CEC62B647569',
    description: 'Build 3 React apps with full tests. Get in demand by adding Jest, Enzyme, ES6, React JS, and Redux to your skill set!',
    includeInSlider: false
  },
  {
    id: 13,
    title: 'React JS - Mastering Redux',
    pic: reduxImage,
    link: 'https://www.udemy.com/course/react-js-mastering-redux/?referralCode=7FB6E6D35AD94CED6E2C',
    description: 'Build the React JS and Redux apps of your dreams! Learn JavaScript, ES6, APIs, and Full Web App Development in ReactJS!',
    includeInSlider: false
  },
  {
    id: 14,
    title: 'Vue JS - Mastering Web Apps',
    pic: vueImage,
    link: 'https://www.udemy.com/course/vue-web-apps/?referralCode=999345ABC2B15AC97986',
    description: 'Create the VueJS 2.0 apps you always wanted. Build 5 apps, learn development secrets, VueRouter, Vuex, & more!',
    includeInSlider: false
  },
  {
    id: 15,
    title: 'The Full JavaScript & ES6 Tutorial - (including ES7 & React)',
    pic: es6InDepthImage,
    link: 'https://www.udemy.com/course/es6-in-depth/?referralCode=28065F545627B09353BF',
    description: 'The most in-depth course on ES6 around. Start with JavaScript, deep-dive into ES6, & even more (Webpack, ES7, & React)!',
    includeInSlider: true
  },
  {
    id: 16,
    title: 'Essentials in JavaScript ES6 - A Fun and Clear Introduction',
    pic: es6Image,
    link: 'https://www.udemy.com/course/essentials-in-javascript-es6/?referralCode=ABC7F2DA7E6A245FB02A',
    description: 'A creative and fun course on JavaScript ES6! Has Webpack, Babel, React, and all the new syntax you need to code in ES6!',
    includeInSlider: true
  },
  {
    id: 17,
    title: 'React JS Web Development - The Essentials Bootcamp',
    pic: reactJSImage,
    link: 'https://www.udemy.com/course/react-js-and-redux-mastering-web-apps/?referralCode=43C900DC597CFCB487D0',
    description: 'Bootcamp on the React.js essentials. Gain a strong foundation of the core concepts, and build exciting and useful apps!',
    includeInSlider: true
  }
];

export default COURSES;