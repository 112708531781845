import React from 'react';
import ReactDOM from 'react-dom';
// Refactor to Next to use Analytics
// import { Analytics } from '@vercel/analytics/react';
import { HashRouter, Route, Redirect, Switch } from 'react-router-dom';
import TicTacToe from './components/TicTacToe';
import AboutPage from './components/AboutPage';
import CoursesPage from './components/CoursesPage';
import ExperiencePage from './components/ExperiencePage';
import ResumeRedirect from './components/ResumeRedirect';
import Header from './components/Header';
import TypeRace from './components/TypeRace';
import PentatonicPrompt from './components/guitar-exercises/PentatonicPrompt';
import './index.css';

const PageWithHeader = ({ children }) => {
  return (
    <div>
      <Header />
      {children}
    </div>
  )
}

// TODO: Consider making a context object. Pass down thinview from the root

ReactDOM.render(
  <HashRouter>
    <Switch>
      <Route path="/about" render={() => <PageWithHeader><AboutPage /></PageWithHeader>} />
      <Route path="/courses" render={() => <PageWithHeader><CoursesPage /></PageWithHeader>} />
      <Route path="/experience" render={() => <PageWithHeader><ExperiencePage /></PageWithHeader>} />
      <Route path="/typerace" render={() => <PageWithHeader><TypeRace /></PageWithHeader>} />
      <Route path="/tictactoe" component={TicTacToe} className="TicTacToe-background" />
      <Route path="/pentatonic-prompt" component={PentatonicPrompt} />
      <Route path="/resume" component={ResumeRedirect} />
      <Redirect from="/" to="/about" />
      <Redirect from="/foo" to="/about" />
    </Switch>
  </HashRouter>,
  document.getElementById('root')
);
