import React, {Component} from 'react';
import Contact from './Contact';
import '../styles/App.css';

{/* <span className="experience-bold"></span> */}
class ExperiencePage extends Component {
  render() {
    return (
      <div className='App-portfolio'>
        <div className="experience-section">
            <div className="experience-block">
                <div className="experience-header">Senior Software Engineer, Full-Stack</div>
                <div className="experience-header-2">Zendesk | July 2021 - June 2022</div>
                <p className="experience-text">* Led a refactor of the Triggers (business rules) page, introducing Trigger Categories. Implemented the backend in <span className="experience-bold">Ruby on Rails, Redis, Aurora, Kafka, Elasticsearch</span>, and the frontend in <span className="experience-bold">React, Redux, TypeScript, GraphQL</span>. Achievement: delivered the enhanced Trigger Categories product to over <span className="experience-bold">160,000 customers</span>, resulting in increased customer satisfaction and contributing to <span className="experience-bold">30% annual revenue growth</span>.</p>
                <p className="experience-text">* Played a pivotal role in creating a <span className="experience-bold">greenfield workflow orchestration service</span>. Built on our own framework written in <span className="experience-bold">Java and Vert.x (reactive, functional programming, event-driven toolkit)</span>. Designed for <span className="experience-bold">highly scalable, fault-tolerant, microservices and distributed systems</span>. Integrated with <span className="experience-bold">DynamoDB, Kafka, Datadog, Consul, Elasticache</span>, and more. <span className="experience-bold">Achievement: framework used by 20% of the engineering org</span>.</p>
                <p className="experience-text">* Led a critical investigation into duplicate actions caused by Automations (hourly jobs on <span className="experience-bold">Resque</span>), and engineered a <span className="experience-bold">Ruby on Rails and Redis-based solution</span> to intelligently resume automations during deployments.</p>
                <p className="experience-text">* Enhanced <span className="experience-bold">observability and monitoring</span> for Triggers and Automations <span className="experience-bold">(Ruby on Rails)</span> by expanding metrics collection and creating detailed <span className="experience-bold">Datadog</span> dashboards and graphs, providing insights into <span className="experience-bold">uptime and availability</span>.</p>
                <p className="experience-text">* Spearheaded the overhaul of a bespoke <span className="experience-bold">legacy JavaScript framework</span>, replacing deprecated dependencies and enhancing browser compatibility by upgrading to <span className="experience-bold">React, Redux, TypeScript, and GraphQL</span>.</p>
                <p className="experience-text">* Streamlined the onboarding process by introducing a mentorship/buddy system, curating a backlog of newcomer-friendly tasks, hosting <span className="experience-bold">cross-team</span> technical presentations, and documenting our agile development practices + tribal engineering knowledge into a detailed guidebook. <span className="experience-bold">Achievement: reduced onboarding time by 25%</span>.</p>
            </div>
        </div>
        <br />
        <div className="experience-section">
            <div className="experience-block">
                <div className="experience-header">Software Engineer, Full-Stack</div>
                <div className="experience-header-2">Zendesk | July 2018 - June 2021</div>
                <p className="experience-text">* <span className="experience-bold">Achievement: Contributed 200+ PRs</span>, participated in numerous incident investigations, and performed hundreds of code reviews driving improvements in <span className="experience-bold">system scalability, fault tolerance, and high availability</span> across the board.</p>
                <p className="experience-text">* Implemented limits within Automations <span className="experience-bold">(Ruby on Rails and Redis)</span> by capping query amounts, restricting automations per customer, and optimizing key processes, which reduced job load and ensured <span className="experience-bold">high availability</span>.</p>
                <p className="experience-text">* Collaborated with <span className="experience-bold">cross-functional</span> teams including stakeholders and architects, to define technical requirements, create user stories, and establish the engineering timeline, leveraging tools like JIRA and Figma.</p>
                <p className="experience-text">* Mentored a team of 5+ engineers on best practices, code quality, and <span className="experience-bold">agile development</span> processes. </p>
                <p className="experience-text">* Selected for a high-impact tiger team to develop the frontend for a crucial project, utilizing <span className="experience-bold">Ember.js and React</span> to deliver a responsive and user-friendly interface.</p>
            </div>
        </div>
        <br />
        <div className="experience-section">
            <div className="experience-block">
                <div className="experience-header">Coding Course Creator</div>
                <div className="experience-header-2">Udemy | 2016 - Present</div>
                <p className="experience-text">* <span className="experience-bold">Published 18 courses with 315,000+</span> students from over 200 countries, fostering engineering growth worldwide.</p>
                <p className="experience-text">* Created multiple bestselling courses, including an <span className="experience-bold">official Docker-endorsed course</span>.</p>
                <p className="experience-text">* <span className="experience-bold"></span>Covered topics including <span className="experience-bold">React, Node.js, Next.js, Docker, PostgreSQL, Test-Driven Development, cloud-native applications</span>, performant web applications, <span className="experience-bold">blockchains & cryptocurrency development</span>, and more.</p>
            </div>
        </div>
        <br />
        <hr className="divider" />
        <br />
        <Contact />
      </div>
    )
  }
}

export default ExperiencePage;
